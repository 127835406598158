export function onPageHide() {
  document.addEventListener('visibilitychange', function () {
    if (document.visibilityState === 'hidden') {
      // 屏幕关闭或锁屏
      console.log('屏幕关闭或锁屏')
      window.location.reload()
      // 在此处执行你想要的操作
    } else {
      // 屏幕重新打开或解锁
      console.log('屏幕重新打开或解锁')
      // 在此处执行你想要的操作
    }
  })
}

// 检查设备是否为iPhone
function isiPhone() {
  return /iPhone/i.test(navigator.userAgent)
}

// 检查浏览器是否为Safari
function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

// 检查浏览器是否为微信浏览器
function isWeChat() {
  return /micromessenger/i.test(navigator.userAgent)
}

// 判断设备是否为iPhone，并且是否使用Safari或微信浏览器
export function isiPhoneWithSafariOrWeChat() {
  return isiPhone() && (isSafari() || isWeChat())
}

export function refreshWithUpdatedParam(paramName: string, paramValue: string) {
  const currentURL = window.location.href
  let newUrl = ''
  const regex = new RegExp('([?&])' + paramName + '=.*?(&|$)', 'i')
  const separator = currentURL.indexOf('?') !== -1 ? '&' : '?'

  if (currentURL.match(regex)) {
    newUrl = currentURL.replace(regex, '$1' + paramName + '=' + paramValue + '$2')
  } else {
    newUrl = currentURL + separator + paramName + '=' + paramValue
  }

  window.location.href = newUrl
}
