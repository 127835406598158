<script setup lang="ts">
import { onPageHide } from '@/assets/utils/pageUtil'
import { useVconsole, setDeviceID } from './assets/utils/envUtil'
import { getPageLoadTime, getTargetDeviceID } from './assets/utils/common'
import { onMounted } from 'vue'
import myLogger from './assets/utils/aliUtils/Logger'

onMounted(() => {
  if (!window.Android) {
    location.href = location.href.replace('app.dadanwuji.com', 'app-test.dadanwuji.com')
    return
  }
  setDeviceID()
  useVconsole()
  onPageHide()
  getTargetDeviceID()
  // 监听页面加载时间
  const loadTime = getPageLoadTime()
  myLogger.log('loadTime', loadTime)
})
</script>

<template>
  <RouterView />
</template>

<style lang="less">
body {
  margin: 0;
  touch-action: none;
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100vw;
    height: 100vh;
  }
  .ant-modal-root .ant-modal-wrap {
    top: 10% !important;
    width: 100vw !important;
  }
}
</style>
