<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { userInfoUtil } from '@/assets/utils/UserInfo'
import { message, Modal } from 'ant-design-vue'
import { getUrlParams } from '@/assets/utils/UrlUtil'
import { isiPhoneWithSafariOrWeChat, refreshWithUpdatedParam } from '@/assets/utils/pageUtil'
import myLogger from '@/assets/utils/aliUtils/Logger'
import { formatCurrentTimeToHHMMSS } from '@/assets/utils/common'
import { getWSURL, getBPWSURL } from '@/assets/utils/envUtil'

let [bgVideo, showBGVideo, name, pwd] = [
  ref<HTMLVideoElement>(),
  ref(true),
  ref('1'),
  ref(import.meta.env.VITE_APP_VERSION),
  ref(''),
  ref('')
]
let robotID: string
const router = useRouter()
let canILogin = false
function play() {
  if (bgVideo.value) {
    bgVideo.value
      .play()
      .then(() => {
        showBGVideo.value = bgVideo.value!.currentTime !== 0
      })
      .catch(() => {
        showBGVideo.value = false
      })
  }
}
let loginState = 0
async function login() {
  myLogger.log({
    page: 'login',
    action: 'loginClick',
    canILogin,
    loginState,
    pwd: pwd.value,
    name: name.value,
    startTime: formatCurrentTimeToHHMMSS(),
    mean: '登录'
  })
  const start = new Date().getTime()

  canILogin = true
  if (loginState) return
  if (!name.value) {
    message.error('用户名不能为空')
    return
  } else if (!pwd.value) {
    message.error('密码不能为空')
    return
  }
  loginState = 1
  const modal = Modal.info({
    content: () => '正在加载帐号：' + name.value + ' 的相关资源，请稍等',
    centered: true,
    footer: ''
  })
  const res = await userInfoUtil.login(name.value, pwd.value)

  loginState = 0
  modal.destroy()
  if (res) {
    myLogger.updateCount(name.value)
    myLogger.log({
      page: 'login',
      action: 'loginInfo',
      timeUse: new Date().getTime() - start,
      startUpTime: userInfoUtil.startUpTime,
      expireTime: userInfoUtil.expireTime,
      userName: name.value,
      sayHi: userInfoUtil.sayHi,
      mean: '登录成功'
    })
    // 判断是否过期
    if (userInfoUtil.expireTime) {
      if (new Date().getTime() >= new Date(userInfoUtil.expireTime).getTime()) {
        userInfoUtil.reset()
        Modal.warning({
          title: () => '注意',
          content: () => '您的使用期已结束，请在公众号充值后再继续使用',
          centered: true,
          footer: '',
          mask: true,
          maskClosable: true
        })
        return
      }
    }

    // 更新用户数据，通知APP
    if (window.Android) {
      window.Android.setWujiAppCount(
        name.value,
        userInfoUtil.userName,
        userInfoUtil.startUpTime ?? '',
        getWSURL() + '?token=' + userInfoUtil.token,
        getBPWSURL() + '?token=' + userInfoUtil.token
      )
    } else {
      // (process.env.VUE_APP_API_URL as string).includes("wujitest") &&
      // enterFullscreen();
    }

    console.warn('login to robot', robotID)
    // 跳转到接听通话页面
    router.push({
      path: 'robot',
      query: {
        waitTime: getUrlParams('openBy') == 'CALL' ? 10 * 1000 : 60 * 1000,
        toPay: getUrlParams('openBy') == 'WECHAT' ? 1 : 0,
        robotID
      }
    })
  }
}

let closeAppTimer: NodeJS.Timeout | undefined
function prepareCloseApp() {
  if (window.Android) {
    closeAppTimer = setTimeout(
      () => {
        myLogger.log({
          page: 'login',
          action: 'closeApp_overTime',
          mean: '超时自动关闭APP'
        })
        window.Android.closeApp()
      },
      1000 * 60 * 5
    )
  }
}

function cancelCloseApp() {
  closeAppTimer && clearTimeout(closeAppTimer)
}

onMounted(() => {
  // 当被用户呼叫时，直接进入
  if (window.Android) {
    myLogger.log({
      page: 'login',
      pwd: pwd.value,
      name: name.value,
      openBy: getUrlParams('openBy'),
      mean: '注册被呼叫onStartTalk'
    })

    // 登录的时候，再次被呼叫
    window.onStartTalk = (_robotID?: string) => {
      robotID = _robotID ?? ''
      myLogger.log({
        page: 'login',
        pwd: pwd.value,
        name: name.value,
        robotID,
        openBy: getUrlParams('openBy'),
        mean: '登录页面状态被呼叫,直接进入开始通话'
      })
      login()
    }
  }

  // 回填登陆信息
  pwd.value = getUrlParams('pwd') || userInfoUtil.getUserPWD()
  name.value = getUrlParams('count') || userInfoUtil.getUserCount()
  robotID = getUrlParams('robotID')
  // 首次进入就记住，即便不登录
  if (pwd.value && name.value) {
    userInfoUtil.setUserName(name.value, pwd.value)
    myLogger.updateCount(name.value)
  }

  play()

  // ios打开时提示不支持
  if (isiPhoneWithSafariOrWeChat()) {
    Modal.warning({
      title: () => '注意',
      content: () => '暂不支持ios，请用电脑上safari或chrome浏览器打开！！',
      centered: true,
      footer: '',
      mask: true
    })
  }

  myLogger.log({
    page: 'login',
    pwd: pwd.value,
    name: name.value,
    openBy: getUrlParams('openBy'),
    mean: '加载登录页面'
  })

  // 微信打开，但是没有注册，则跳转到创建帐号页面
  if (getUrlParams('openBy') == 'WECHAT' && !name.value) {
    // window.location.href = `https://weixin${location.href.includes('test') ? '-test' : ''}.dadanwuji.com/create`
    return
    // app打开，且登录过，直接进入通话页面
  } else if (name.value && pwd.value && getUrlParams('openBy') == 'CALL') {
    login()
  } else {
    // 2s后才可以进入，防止过快点击退出又立马进入
    setTimeout(() => {
      // can login
      canILogin = true
    }, 2 * 1000)

    prepareCloseApp()
  }
})

onUnmounted(() => {
  myLogger.log({
    page: 'login',
    name: name.value,
    mean: '卸载登录页面'
  })
  cancelCloseApp()
})
</script>

<template>
  <div class="login">
    <video
      v-show="showBGVideo"
      ref="bgVideo"
      src="https://static.dadanwuji.com/app/android/loginBackground.mp4"
      loop
      autoplay
      muted
      x5-playsinline
      playsinline
      :oncanplay="play"
      webkit-playsinline="true"
    ></video>
    <img v-show="!showBGVideo" src="https://static.dadanwuji.com/app/android/loginBackground.jpg" />

    <div class="loginBox">
      <div class="userName">
        <label>帐号</label> <input v-model="name" placeholder="手机号/用户名" />
      </div>
      <div class="pwd"><label>密码</label> <input v-model="pwd" placeholder="请输入密码" /></div>
      <div class="loginBtn" @click="canILogin && login()"><label>登&nbsp;&nbsp;录</label></div>
      <div class="cEMOSZ">
        登录视为您已同意<span class="hover-orange">
          <a href="https://static.dadanwuji.com/app/android/TermsService.html" target="_blank"
            >服务条款</a
          >、<a href="https://static.dadanwuji.com/app/android/PrivacyPolicy.html" target="_blank"
            >隐私政策</a
          ></span
        >
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@import '../assets/css/index.less';
.login {
  width: 100%;
  height: 100%;
  position: relative;
  input {
    width: 180px;
  }
  video,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
  }
  .loginBox {
    &::before {
      content: '';
      display: block;
      width: 228px;
      height: 91px;
      position: absolute;
      top: -110px;
      background-image: url('../assets/img/loginTitle@2x.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    position: absolute;
    left: 50%;
    top: 63%;
    transform: translateX(-50%) translateY(-50%);
    font-family:
      PingFangSC,
      PingFang SC;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    .pxToVW(45,padding-left);
    .pxToVW(45,padding-right);
    .pxToVW(46,padding-bottom);
    .pxToVW(35,padding-top);
    background-color: #ffffff;
    border-radius: 5px;
    .userName,
    .pwd,
    .loginBtn {
      .pxToVW(30,font-size);
      .pxToVW(15,margin-top);
      background: #ffffff;
      border: 1px solid rgb(217, 217, 217);
      border-radius: 5px;
      padding: 14px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      color: #282828;
      width: 100%;
      box-sizing: border-box;
      label,
      input {
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 400;
        font-size: 20px;
        line-height: 18px;
        outline: none;
      }
      label {
        white-space: nowrap;
      }
      input {
        margin-left: 10px;
        flex: 1;
        border: 0;
      }
    }
    .loginBtn {
      justify-content: center;
      color: #ffffff;
      font-weight: 600;
      .pxToVW(29.5,margin-top);
      background: linear-gradient(to right, #ffab68, #f3774d);
    }
  }
}
.cEMOSZ {
  padding: 10px;
  font-size: 12px;
  color: rgb(179, 179, 179);
  a {
    color: rgb(179, 179, 179);
  }
  :hover {
    color: #ff6a00 !important;
  }
}
</style>
