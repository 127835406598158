import message from 'ant-design-vue/es/message'
import axios from 'axios'
import myLogger from './aliUtils/Logger'
import { getDeviceID, getHTTPURL } from './envUtil'

abstract class UserInfo {
  maxSilence!: number
  userID!: number
  listenVideo!: string
  speakVideo!: string
  waitVideo!: string
  avatar!: string
  replyConstraintsName?: string
  micID?: string
  speakerID?: string
  token?: string
  userName!: string
  startUpTime?: string
  expireTime?: string
  pid!: string
  sayHi?: string
  vtt: {
    token: string
    expireTime: string
    appKey: string
  } = {
    token: '',
    expireTime: '',
    appKey: ''
  }
}

class UserInfoUtil extends UserInfo {
  checkUserLoggedIn() {
    return this.userID
  }

  async login(count: string, pwd: string): Promise<boolean> {
    try {
      const userInfo = await this.getUserInfo(count, pwd)
      if (userInfo && userInfo.userName) {
        this.setUserInfo(userInfo)
        this.setUserName(count, pwd)
        return true
      } else {
        //@ts-ignore
        const errorMsg = (userInfo && userInfo['error']) ?? '登录失败'
        myLogger.error({
          page: 'login',
          action: 'loginInfo',
          error: errorMsg
        })

        message.error(errorMsg)
        return false
      }
    } catch (error) {
      console.error(error)
      // 如果登录失败，那么就把错误信息提示给用户
      return false
    }
  }

  async getUserInfo(count: string, pwd: string): Promise<UserInfo | null> {
    const url: string = getHTTPURL() + '/api/login'

    try {
      const result = await axios.post(url, {
        count,
        pwd,
        deviceID: getDeviceID()
      })
      const data = result.data
      return data
    } catch (error) {
      myLogger.error({
        page: 'login',
        action: 'loginInfo',
        error: JSON.stringify(error)
      })
      console.error(error)
      return null
    }
  }

  setUserInfo(uInfo: UserInfo) {
    this.avatar = uInfo.avatar
    this.userName = uInfo.userName
    this.token = uInfo.token
    this.replyConstraintsName = uInfo.replyConstraintsName
    this.listenVideo = uInfo.listenVideo
    this.speakVideo = uInfo.speakVideo
    this.waitVideo = uInfo.waitVideo
    this.startUpTime = uInfo.startUpTime
    this.expireTime = uInfo.expireTime
    this.pid = uInfo.pid
    this.vtt.appKey = uInfo.vtt.appKey
    this.vtt.token = uInfo.vtt.token
    this.sayHi = uInfo.sayHi
    this.maxSilence = uInfo.maxSilence * 1
    this.userID = uInfo.userID
  }

  setUserName(count: string, pwd: string) {
    localStorage.setItem(
      'userInfo',
      JSON.stringify({
        count,
        pwd
      })
    )
  }
  getUserCount() {
    const userInfoStr = localStorage.getItem('userInfo')
    if (userInfoStr) {
      return JSON.parse(userInfoStr).count
    } else {
      return ''
    }
  }

  getUserPWD() {
    const userInfoStr = localStorage.getItem('userInfo')
    if (userInfoStr) {
      return JSON.parse(userInfoStr).pwd
    } else {
      return ''
    }
  }

  clearUserInfo() {
    localStorage.removeItem('userInfo')
  }
  reset() {
    this.avatar = ''
    this.userName = ''
    this.token = ''
    this.replyConstraintsName = ''
    this.listenVideo = ''
    this.speakVideo = ''
    this.waitVideo = ''
    this.startUpTime = ''
    this.expireTime = ''
    this.pid = ''
    this.vtt.appKey = ''
    this.vtt.token = ''
    this.sayHi = ''
  }
}

export const userInfoUtil = new UserInfoUtil()
