<script setup lang="ts">
import { userInfoUtil } from '@/assets/utils/UserInfo'
import { onBeforeMount, onMounted, onUnmounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import myLogger from '@/assets/utils/aliUtils/Logger'
import { getRobots, setCurrentRobot } from '@/assets/service/httpService'
import { Modal } from 'ant-design-vue'
import { nextTick } from 'process'

// 双向绑定变量
let avatar = ref(''),
  voice = ref<HTMLAudioElement>(),
  avartList = ref<
    {
      robotID: number
      avatar: string
      listenVideo: string
      speakVideo: string
      waitVideo: string
    }[]
  >([]),
  avatarListContainer = ref<HTMLDivElement>()

// 路由处理变量
const router = useRouter()
const route = useRoute()
let robotID = route.query.robotID as string
let selectedAvatarIndex = ref(0)
// 局部变量
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let waitTimeTimer: NodeJS.Timeout | undefined, callVoiceTimer: NodeJS.Timeout | undefined

onBeforeMount(() => {
  // 获取身份相关数据
  avatar.value = userInfoUtil.avatar
  if (avatar.value) {
    avartList.value.push({
      robotID: 99999999,
      avatar: avatar.value,
      listenVideo: userInfoUtil.listenVideo,
      speakVideo: userInfoUtil.speakVideo,
      waitVideo: userInfoUtil.waitVideo
    })
  }
  getOtherRobots(userInfoUtil.userID)
})

onMounted(() => {
  callVoiceTimer = setTimeout(() => {
    voice.value!.muted = false
    callVoiceTimer = undefined
  }, 1000 * 1)

  // 响铃最多60S 如果被动呼叫接听等待最多10S
  const waitTime = route.query.waitTime as string
  waitTimeTimer = setTimeout(
    () => {
      myLogger.log({
        page: 'chat',
        action: 'auto_start_overtime',
        mean: '超时自动接听'
      })
      waitTimeTimer = undefined
      startTalk()
    },
    waitTime ? Number.parseInt(waitTime) : 60 * 1000
  )

  // 呼叫页面被呼叫，切换到指定机器人
  window.onStartTalk = (_robotID?: string) => {
    robotID = _robotID ?? ''
    selectRobot()
    myLogger.log({
      page: 'robot',
      mean: '机器人列表被呼叫'
    })
  }
})

onUnmounted(() => {
  myLogger.log('start chat', '开始会话')
  // 清除定时器
  waitTimeTimer && clearTimeout(waitTimeTimer)
})

async function startTalk() {
  // 关闭铃声，开始通话
  // eslint-disable-next-line no-undef
  voice.value!.muted = true
  waitTimeTimer && clearTimeout(waitTimeTimer)
  const container = avatarListContainer.value
  if (container && container.children.length > 0) {
    const robot = avartList.value[selectedAvatarIndex.value]

    if (robot && robot.robotID != 0) {
      userInfoUtil.listenVideo = robot.listenVideo
      userInfoUtil.speakVideo = robot.speakVideo
      userInfoUtil.waitVideo = robot.waitVideo
      const result = await setCurrentRobot(robot.robotID, userInfoUtil.getUserCount())
      if (result) {
        userInfoUtil.sayHi = result
        myLogger.log('startTalk', '选择多角色成功：' + robot.robotID)
        router.push({
          path: 'chat',
          query: { ...route.query }
        })
      } else {
        myLogger.log('startTalk', '选择多角色失败：' + robot.robotID)
      }
      return
    }
  }

  router.push({
    path: 'chat'
  })
}

async function refuseTalk() {
  // 清除定时器
  callVoiceTimer && clearTimeout(callVoiceTimer)
  waitTimeTimer && clearTimeout(waitTimeTimer)

  setTimeout(() => {
    router.push({
      path: 'login'
    })
  }, 500)
}
async function getOtherRobots(userID: number) {
  try {
    const robots = await getRobots(userID)
    if (robots.length > 0) {
      robots.forEach((item) => {
        avartList.value.push({
          robotID: item.id,
          avatar: item.avatar,
          listenVideo: item.listenVideo,
          speakVideo: item.speakVideo,
          waitVideo: item.waitVideo
        })
      })
    }

    // 没有创建机器人，提示必须先创建机器人
    if (avartList.value.length < 1) {
      Modal.info({
        content: () => '您还没有创建机器人，请先在微信公众号创建',
        centered: true,
        //  footer: '',
        okText: '知道了',
        onOk: () => {
          router.push({
            path: 'login'
          })
        }
      })
      // 清除定时器
      callVoiceTimer && clearTimeout(callVoiceTimer)
      waitTimeTimer && clearTimeout(waitTimeTimer)
      voice.value!.muted = true
      return
    }
    selectRobot()
  } catch (error) {
    myLogger.log('getOtherRobots', '获取多角色失败')
  }
}

function selectRobot() {
  console.warn('selectRobot', robotID)
  avartList.value.forEach((robot, index) => {
    if (robot.robotID == Number.parseInt(robotID)) {
      selectedAvatarIndex.value = index
      nextTick(() => {
        // 滚动到选中的机器人上
        scrollToTarget()
      })
    }
  })
}

function scrollByFixedDistance(toRight: boolean) {
  const container = avatarListContainer.value
  if (container && container.children.length > 0) {
    const childWidth = container.children[0].clientWidth
    if (toRight && selectedAvatarIndex.value < avartList.value.length - 1) {
      ++selectedAvatarIndex.value
      container.scrollTo({
        left: container.scrollLeft + 1.5 * childWidth,
        behavior: 'smooth'
      })
      // container.scrollLeft = container.scrollLeft + 1.5 * childWidth // 每次滚动一个子元素的宽度
    } else if (!toRight && selectedAvatarIndex.value > 0) {
      --selectedAvatarIndex.value
      // container.scrollLeft = container.scrollLeft - 1.5 * childWidth // 每次滚动一个子元素的宽度
      container.scrollTo({
        left: container.scrollLeft - 1.5 * childWidth,
        behavior: 'smooth'
      })
    }
  }
}

function scrollToTarget() {
  const container = avatarListContainer.value
  if (container && container.children.length > 0) {
    const childWidth = container.children[0].clientWidth
    container.scrollTo({
      left: container.scrollLeft + 1.5 * childWidth * selectedAvatarIndex.value,
      behavior: 'smooth'
    })
  }
}
</script>
<template>
  <div class="home">
    <div class="logo"></div>
    <audio
      ref="voice"
      autoplay
      src="https://static.dadanwuji.com/app/android/incomming_phone.mp3"
      width="1px"
      height="1px"
      loop
      muted
    ></audio>

    <div class="avatarContainer">
      <div
        v-if="avartList.length > 1"
        :class="{ scrollToLeft: true, disabled: selectedAvatarIndex == 0 }"
        @click="scrollByFixedDistance(false)"
      ></div>
      <div class="avatarList" ref="avatarListContainer">
        <div class="avatarBG" v-for="(item, index) in avartList" :key="'avatat' + index">
          <div class="avatar" :style="{ backgroundImage: `url(${item.avatar})` }"></div>
        </div>
      </div>
      <div
        v-if="avartList.length > 1"
        :class="{ scrollToRight: true, disabled: selectedAvatarIndex == avartList.length - 1 }"
        @click="scrollByFixedDistance(true)"
      ></div>
    </div>

    <div class="handlerBG">
      <div class="refuseBG">
        <span class="refuseBGIcon01"></span>
        <span class="refuseBGIcon02"></span>
        <button class="refuse" @click="refuseTalk">
          <span class="refuse_icon"></span>
          <span class="refuse_text">点击拒绝</span>
        </button>
      </div>
      <div class="acceptBG">
        <span class="acceptBGIcon01"></span>
        <span class="acceptBGIcon02"></span>
        <button class="accept" @click="startTalk">
          <span class="accept_icon"></span>
          <span class="accept_text">点击接听</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import '../assets/css/index.less';

@keyframes scaleFadeAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
.home {
  padding: 35px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url('../assets/img/homeBackground@2x.png');
  position: relative;
  .logo {
    .pxToVW(129.5,width);
    .pxToVW(43,height);
    position: absolute;
    background-image: url(../assets/img/homeLogo@2x.png);
    background-size: contain;
    .pxToVW(60,left);
    .pxToVW(47,top);
    background-repeat: no-repeat;
  }

  .avatarContainer {
    display: flex;
    align-items: center;
    .scrollToLeft,
    .scrollToRight {
      .pxToVW(125,width);
      .pxToVW(125,height);
      background-size: contain;
    }
    .scrollToLeft {
      .pxToVW(125,margin-right);
      background-image: url('../assets/img/chatRobotLeft.png');
    }
    .scrollToRight {
      .pxToVW(125,margin-left);
      background-image: url('../assets/img/chatRobotRight.png');
    }
    .disabled {
      opacity: 0.5; /* 会使元素整体变半透明，包括背景和内容 */
    }
    .avatarList {
      display: flex;
      .pxToVW(440,width);
      overflow-x: auto;
      -ms-overflow-style: none; /* 适用于 Internet Explorer 和 Edge */
      &::-webkit-scrollbar {
        display: none; /* 适用于 Chrome, Safari 和 Opera */
      }
      .avatarBG {
        display: flex;
        justify-content: center;
        align-items: center;
        .pxToVW(440,width);
        .pxToVW(440,height);
        .pxToVW(220,margin-right);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url(../assets/img/homeAvatarBG@2x.png);
        .avatar {
          .pxToVW(420,width);
          .pxToVW(420,height);
          border: 0;
          border-radius: 50%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      .avatarBG:last-child {
        margin-right: 0;
      }
    }
  }

  .handlerBG {
    display: flex;
    justify-content: space-around;
    align-items: start;
    width: 50%;
    .acceptBG,
    .refuseBG {
      .pxToVW(214,width);
      .pxToVW(214,height);
      display: flex;
      justify-content: center;
      align-items: center;
      > span {
        position: absolute;
        .pxToVW(125,width);
        .pxToVW(125,height);
        .pxToVW(125, border-radius);
        background-color: #f38a4d;
        animation: scaleFadeAnimation 2s ease-in-out infinite;
      }
      .acceptBGIcon01,
      .refuseBGIcon01 {
        animation-delay: 0ms;
      }
      .acceptBGIcon02,
      .refuseBGIcon02 {
        animation-delay: 1.5s;
      }
      .accept,
      .refuse {
        position: absolute;
        .pxToVW(125,width);
        .pxToVW(125,height);
        .pxToVW(125, border-radius);
        border: 0;
        background-image: url(../assets/img/homeAcceptBTN@2x.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .accept_icon,
        .refuse_icon {
          .pxToVW(63,width);
          .pxToVW(63,height);
          background-image: url(../assets/img/homePhone@2x.png);
          background-size: contain;
        }
        .accept_text,
        .refuse_text {
          .pxToVW(19, font-size);
          font-family:
            PingFangSC-Semibold,
            PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 18px;
          text-shadow: 1px 1px 11px rgba(36, 21, 9, 0.41);
          -webkit-background-clip: text;
          .pxToVW(20, margin-bottom);
        }
      }
      .accept {
        background-image: unset;
        background-color: #0bd10b;
      }
    }
    .acceptBG {
      > span {
        background-color: #28ca28f0;
      }
    }
  }
}

/* 竖屏样式 */
@media screen and (orientation: portrait) {
  /* 在竖屏时应用的样式 */
  .home {
    .avatarBG {
      .pxToVW(546*2,width);
      .pxToVW(546*2,height);
      .avatar {
        .pxToVW(520*2,width);
        .pxToVW(520*2,height);
      }
    }
    .handlerBG {
      width: 90%;
      .acceptBG,
      .refuseBG {
        .pxToVW(214,width);
        .pxToVW(214,height);
        .pxToVW(61.5*2, margin-top);
        > span {
          position: absolute;
          .pxToVW(125*2,width);
          .pxToVW(125*2,height);
          .pxToVW(125*2, border-radius);
        }
        .accept,
        .refuse {
          .pxToVW(250,width);
          .pxToVW(250,height);
          .pxToVW(250, border-radius);
          .accept_icon,
          .refuse_icon {
            .pxToVW(63*1.5,width);
            .pxToVW(63*1.5,height);
          }
          .accept_text,
          .refuse_text {
            .pxToVW(19*2, font-size);
          }
        }
      }
    }
  }
}

@keyframes circleFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
