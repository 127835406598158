import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import chatView from '../views/ChatView.vue'
import ChatRobot from '../views/ChatRobot.vue'
import { userInfoUtil } from '@/assets/utils/UserInfo'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/robot',
      name: 'robot',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // () => import('../views/AboutView.vue')
      component: ChatRobot
    },
    {
      path: '/chat',
      name: 'chat',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // () => import('../views/AboutView.vue')
      component: chatView
    },
    {
      path: '/sambertTool',
      name: 'sambertTool',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/SambertToolView.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  document.title = (to.meta.title as string) || '达旦认知陪伴'
  // 检查用户是否已登录
  const isLoggedIn = userInfoUtil.checkUserLoggedIn()
  const toName = to.name as string
  console.log('beforeEach', isLoggedIn, toName)
  if (isLoggedIn || ['review', 'chatLogs', 'login', 'service', 'sambertTool'].includes(toName)) {
    // 动态添加参数到目标路由
    to.params.from = '' + from.path
    // 用户已登录，继续导航到目标路由
    next()
  } else {
    console.log('beforeEach', '重新跳转到登陆页面')
    // 用户未登录，重定向到登录页
    next('/login')
  }
})
export default router
