<script setup lang="ts">
import { userInfoUtil } from '@/assets/utils/UserInfo'
import { onBeforeMount, onMounted, onUnmounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { askFirstQuestion, formatCurrentTimeToYYYYMMDDHHMMSS } from '@/assets/utils/common'
import AiPlayer from '@/components/AiPlayer.vue'
import myLogger from '@/assets/utils/aliUtils/Logger'
import { recordLive } from '@/assets/service/httpService'
import { errorHandler } from '@/assets/utils/errorUtil'

// 双向绑定变量
let listenURL = ref(''),
  replyRealURL = ref(''),
  waitURL = ref(''),
  state = ref('listening'),
  HumanState = ref('listening'),
  showEnd = ref(true),
  showRealHuman = ref(false)
const route = useRoute()
let robotID = route.query.robotID as string
let toChangeRobot = false

// 路由处理变量
const router = useRouter()

// 局部变量
let sigleTalkLimitTimeTimer: NodeJS.Timeout | undefined

onBeforeMount(() => {
  // 获取身份相关数据
  listenURL.value = userInfoUtil.listenVideo
  replyRealURL.value = userInfoUtil.speakVideo
  waitURL.value = userInfoUtil.waitVideo
})

function forbidBack() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  history.pushState(null, null, location.href)
}

onMounted(() => {
  // 通话页面被呼叫，退出当前页面，重新到登录页面
  window.onStartTalk = (_robotID?: string) => {
    myLogger.log({
      page: 'chat',
      action: 'onStartTalk',
      mean: '通话中切换机器人'
    })
    if (robotID != _robotID && _robotID) {
      toChangeRobot = true
      robotID = _robotID
      refuseTalk()
    }
  }

  // 单次聊天时长限制
  sigleTalkLimitTimeTimer = setTimeout(
    () => {
      window.Android.stopTalk(
        '我妈妈叫我去写作业了，' + userInfoUtil.userName + ',我们下次再聊吧，一定要记得我哦'
      )
      myLogger.log({
        page: 'chat',
        action: 'closeApp_overtime'
      })
      setTimeout(() => {
        refuseTalk()
      }, 10 * 1000)
    },
    1000 * 60 * 30 // 最大40min
  )

  // 监听androios的状态回调
  window.onAiStateChanged = (aitate: string) => {
    state.value = aitate
  }
  window.onHumanStateChanged = (humanState: string) => {
    HumanState.value = humanState
  }

  //拦截安卓回退按钮
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  history.pushState(null, null, location.href)
  window.addEventListener('popstate', forbidBack)
  window.onAndroidError = (error?: string) => {
    myLogger.log({
      page: 'chat',
      action: 'android_error',
      error
    })
    errorHandler('抱歉！！程序发生错误,正在为您重启')
  }

  const streamID =
    'response_' + userInfoUtil.getUserCount() + '_' + formatCurrentTimeToYYYYMMDDHHMMSS()
  // 初始化语音转文字
  window.Android.initASR(
    userInfoUtil.vtt.appKey,
    userInfoUtil.vtt.token,
    streamID,
    userInfoUtil.maxSilence,
    40
  )
  recordLive(userInfoUtil.pid, streamID, Number.parseInt(robotID))

  // 主动开启第一句问候
  setTimeout(() => {
    const sayHi = userInfoUtil.sayHi
      ? userInfoUtil.sayHi
      : askFirstQuestion(userInfoUtil.replyConstraintsName!, userInfoUtil.userName)

    myLogger.log({
      page: 'chat',
      action: '问候：' + sayHi,
      sayHi
    })

    window.Android && window.Android.startTalk(sayHi)
    showEnd.value = false
  }, 1000 * 2)
})

onUnmounted(() => {
  myLogger.log('logout', '结束会话')
  unInit()
})

function refuseTalk() {
  myLogger.log('refuseTalk', '关闭会话')
  window.Android.exitTalk()
  unInit()
}

function toShowEnd() {
  myLogger.log({
    page: 'chat',
    action: 'toShowEnd',
    mean: '点击准备退出'
  })
  showEnd.value = true
  setTimeout(() => {
    showEnd.value = false
  }, 3000)
}

function unInit() {
  window.removeEventListener('popstate', forbidBack) //移除拦截安卓回退按钮
  // 清除定时器
  sigleTalkLimitTimeTimer && clearTimeout(sigleTalkLimitTimeTimer)
  sigleTalkLimitTimeTimer = undefined
  // 清除用户信息
  userInfoUtil.reset()
  setTimeout(() => {
    router.push({
      path: 'login',
      query: {
        openBy: toChangeRobot ? 'CALL' : '',
        robotID: toChangeRobot ? robotID : ''
      }
    })
  }, 500)
}
</script>
<template>
  <div class="chat" @click="toShowEnd">
    <div class="logo"></div>
    <audio
      volume="1"
      v-if="listenURL.includes('yikong')"
      src="https://static.dadanwuji.com/app/android/yikong/background.MP3"
      autoplay
      loop
    ></audio>
    <AiPlayer
      v-show="!showRealHuman"
      ref="aiPlayer"
      :state="state"
      :listenURL="listenURL"
      :replyRealURL="replyRealURL"
      :waitURL="waitURL"
    ></AiPlayer>
    <div class="speak">
      <!--  -->
      <div class="tospeak" v-show="HumanState === 'waiting'">
        <div class="micIcon"></div>
        <p>请说话</p>
      </div>

      <!--  -->
      <div class="speaking" v-show="HumanState === 'speaking'">
        <img src="https://static.dadanwuji.com/app/android/talking_2x.gif" />
      </div>

      <!-- -->
      <div class="speakEnd" v-show="HumanState === 'translating'">
        <div class="waitIcon">
          <icon></icon>
          <icon></icon>
          <icon></icon>
          <icon></icon>
          <icon></icon>
        </div>
        <p>思考中</p>
      </div>
    </div>
    <transition name="fade">
      <div class="refuseBG" v-show="showEnd">
        <button class="refuse" @click="refuseTalk">
          <span class="refuse_icon"></span>
          <span class="refuse_text">点击挂断</span>
        </button>
      </div>
    </transition>
  </div>
</template>

<style lang="less" scoped>
@import '../assets/css/index.less';

@keyframes circleFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.chat {
  width: 100%;
  height: 100%;
  background: black;
  .logo {
    z-index: 1;
    .pxToVW(129.5,width);
    .pxToVW(43,height);
    position: absolute;
    background-image: url(../assets/img/chatLog@2x.png);
    background-size: contain;
    .pxToVW(60,left);
    .pxToVW(47,top);
    background-repeat: no-repeat;
  }

  .subtitles {
    bottom: 5px;
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    font-size: 20px;
    font-family: fangsong;
    font-weight: 800;
    color: white;
  }
  .speak {
    z-index: 2;
    position: absolute;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100vw;

    .tospeak,
    .speaking,
    .speakEnd {
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .tospeak {
      .pxToVW(196,height);
      background-image: url(../assets/img/chatToStartMicBG@2x.png);
      .micIcon {
        background-image: url(../assets/img/chatToStartMic@2x.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        .pxToVW(66,height);
        .pxToVW(66,width);
      }
      p {
        .pxToVW(50,font-size);
        font-family:
          PingFangSC-Semibold,
          PingFang SC;
        font-weight: 600;
        color: #ffffff;
        .pxToVW(50,line-height);
        margin-bottom: 0;
      }
    }
    .speaking {
      .pxToVW(105,height);
      background-image: url(../assets/img/chatToStartBG@2x.png);
      video,
      img {
        width: 100%;
        height: 100%;
      }
    }
    .speakEnd {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .pxToVW(484,width);
      .pxToVW(127,height);
      background-image: url(../assets/img/chatTalkEndBG@2x.png);
      .waitIcon {
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        icon {
          animation: circleFade 2.5s infinite;
          .pxToVW(14,height);
          .pxToVW(14,width);
          .pxToVW(14,margin-right);
          background-size: contain;
          background-repeat: repeat-x;
          display: inline-block;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url(../assets/img/circle@2x.png);
        }
        icon:nth-child(1) {
          animation-delay: 0s;
        }
        icon:nth-child(2) {
          animation-delay: 0.5s;
        }
        icon:nth-child(3) {
          animation-delay: 1s;
        }
        icon:nth-child(4) {
          animation-delay: 1.5s;
        }
        icon:nth-child(5) {
          animation-delay: 2s;
        }
      }
      p {
        .pxToVW(50,font-size);
        .pxToVW(50,line-height);
        font-family:
          PingFangSC-Semibold,
          PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin: 10px 0 0 0;
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .refuseBG {
    display: flex;
    justify-content: center;
    align-items: start;
    width: 100%;
    position: absolute;
    bottom: 44vh;
    z-index: 2;
    .refuse {
      position: absolute;
      .pxToVW(125,width);
      .pxToVW(125,height);
      .pxToVW(125, border-radius);
      border: 0;
      background-image: url(../assets/img/homeAcceptBTN@2x.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .refuse_icon {
        .pxToVW(63,width);
        .pxToVW(63,height);
        background-image: url(../assets/img/homePhone@2x.png);
        background-size: contain;
      }
      .refuse_text {
        .pxToVW(19, font-size);
        font-family:
          PingFangSC-Semibold,
          PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 18px;
        text-shadow: 1px 1px 11px rgba(36, 21, 9, 0.41);
        -webkit-background-clip: text;
        .pxToVW(20, margin-bottom);
      }
    }
  }
}

/* 竖屏样式 */
@media screen and (orientation: portrait) {
  .chat {
    .speak {
      .tospeak,
      .speaking,
      .speakEnd {
        background-size: cover;
      }
      .tospeak {
        .pxToVW(196*2.2,height);
        .micIcon {
          .pxToVW(66*2,height);
          .pxToVW(66*2,width);
        }
        p {
          .pxToVW(50*1.5,font-size);
        }
      }
      .speaking {
        .pxToVW(105*2,height);
      }
      .speakEnd {
        .pxToVW(484*2,width);
        .pxToVW(127*2,height);
        p {
          .pxToVW(50*1.5,font-size);
          .pxToVW(50*1.5,line-height);
        }
      }
    }
    .refuseBG {
      .refuse {
        .pxToVW(250,width);
        .pxToVW(250,height);
        .pxToVW(250, border-radius);
        .refuse_icon {
          .pxToVW(63*1.5,width);
          .pxToVW(63*1.5,height);
        }
        .refuse_text {
          .pxToVW(19*2, font-size);
        }
      }
    }
  }
}
</style>
