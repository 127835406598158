import { Modal, type ModalFuncProps } from 'ant-design-vue'

let warning: { destroy: () => void; update: (newConfig: ModalFuncProps) => void }
export function errorHandler(error: string) {
  console.log('errorHandler', error)
  if (!warning) {
    warning = Modal.warning({
      title: () => '提醒',
      content: error + '，稍后自动重启',
      centered: true,
      footer: ''
    })
  } else {
    warning.update({
      content: error + '，稍后自动重启'
    })
  }

  setTimeout(() => {
    window.location.reload()
  }, 5000)
}
