export function getUrlParams(key: string): string {
  const searchParams = window.location.search.substr(1).split('&')
  const params: { [index: string]: string } = {}

  for (const param of searchParams) {
    const [key, value] = param.split('=')
    params[key] = decodeURIComponent(value)
  }
  return params[key] ?? ''
}
