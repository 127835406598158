import type { WebTrackerBrowserOptions } from '@aliyun-sls/web-types'
import { formatCurrentTimeToHHMMSS } from '../common'
import axios from 'axios'

class MyLogger {
  private seq: number = 0

  // private tracker: SlsTracker
  private options!: WebTrackerBrowserOptions
  private url = `https://wuji-ai-app.cn-shenzhen.log.aliyuncs.com/logstores/web-app/track?APIVersion=0.6.0`

  constructor(opts: WebTrackerBrowserOptions) {
    // this.tracker = new SlsTracker(opts)
    this.options = opts
    this.url = `https://${opts.project}.${opts.host}/logstores/${opts.logstore}/track?APIVersion=0.6.0&source=${opts.source}&count=${opts.count}`
  }

  public log(key: string | Object, value?: string | number) {
    console.log(key, value)
    this.send('log', key, value)
  }
  public warn(key: string | Object, value?: string | number) {
    console.warn(key, value)
    this.send('warn', key, value)
  }
  public error(key: string | Object, value?: string | number) {
    console.error(key, value)
    this.send('error', key, value)
  }
  public updateCount(count: string) {
    this.options.tags!.count = count
    this.url = `https://${this.options.project}.${this.options.host}/logstores/${this.options.logstore}/track?APIVersion=0.6.0&source=${this.options.source}&count=${this.options.tags!.count}`
    // this.tracker = new SlsTracker({ ...this.tracker.getOpt(), ...opt })
  }

  private send(type: 'log' | 'warn' | 'error', key: string | Object, value?: string | number) {
    let _log: { [index: string]: any } = {
      seq: 'step' + this.seq++,
      type,
      time: formatCurrentTimeToHHMMSS()
    }
    if (typeof key === 'string') {
      _log = {
        [key]: value,
        ..._log
      }
    } else if (typeof key === 'object') {
      _log = { ..._log, ...key }
    }

    // this.tracker.send(_log)
    // 手动构建查询字符串
    const parts = []
    for (const key in _log) {
      if (Object.prototype.hasOwnProperty.call(_log, key)) {
        parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(_log[key]))
      }
    }
    const queryString = parts.join('&')
    const getUrl = this.url + '&' + queryString
    axios.get(getUrl)
  }
}

function getUserCount() {
  const userInfoStr = localStorage.getItem('userInfo')
  if (userInfoStr) {
    return JSON.parse(userInfoStr).count
  } else {
    return 'none'
  }
}

const myLogger = new MyLogger({
  host: 'cn-shenzhen.log.aliyuncs.com', // 所在地域的服务入口。例如cn-hangzhou.log.aliyuncs.com
  project: 'wuji-ai-app', // Project名称。
  logstore: 'web-app', // Logstore名称。
  time: 10, // 发送日志的时间间隔，默认是10秒。
  count: 10, // 发送日志的数量大小，默认是10条。
  topic: 'wuji-web-app', // 自定义日志主题。
  source: navigator.appVersion
    ? navigator.appVersion.includes('TBS')
      ? 'TBS'
      : navigator.appVersion
    : 'unknown',
  tags: {
    count: getUserCount()
  }
})
export default myLogger
