import Vconsole from 'vconsole'
import { getURLParam } from './common'
export function useVconsole() {
  if (location.href.includes('debug')) {
    // 测试和开发打开，生产不能打开
    const vConsole = new Vconsole()
  }
}

export function setDeviceID() {
  const deviceID = getURLParam('deviceID')
  if (deviceID) {
    localStorage.setItem('deviceID', deviceID)
  }
}
export function getDeviceID(): string {
  return localStorage.getItem('deviceID') ?? ''
}

export function getWSURL() {
  return 'wss://' + location.host + '/ws'
}

export function getBPWSURL() {
  return (
    'wss://' +
    (location.host.includes('app')
      ? location.host.replace('app', 'prod')
      : location.host.replace('prod', 'app')) +
    '/ws'
  )
}

export function getHTTPURL() {
  return 'https://' + location.host
}
