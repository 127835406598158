export class RetryStrategy {
  private count = 0;
  private maxCount = 3;
  private intervals = [2, 2];
  private _excutor: () => void;
  private _finishCallBack?: () => void;
  private state: "waiting" | "done" = "done";
  constructor(excutor: () => void) {
    this._excutor = excutor;
  }

  excute() {
    if (this.state !== "done") {
      console.log("retrying,pls wait");
      return;
    } else {
      this.state = "waiting";
    }
    if (this.count < this.maxCount) {
      setTimeout(() => {
        this._excutor();
        this.state = "done";
      }, this.intervals[this.count]);
      this.count++;
    } else {
      this._finishCallBack && this._finishCallBack();
    }
  }

  onFinish(done: () => void) {
    this._finishCallBack = done;
  }

  reset() {
    this.count = 0;
    this.state = "done";
  }
}
